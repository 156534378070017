import {
  CREATE_PLAYER,
  PLAY_AND_PAUSE,
  PLAYER_READY,
  UPDATE_PROGRESS,
  DESTROY_PLAYER,
  SPOOLING_PLAYER,
  PAUSED,
  SET_PLAYER_ACTION_TYPE,
  //, ERROR_PLAYER, RESET_DEBUG_PLAYER, DEBUG_PLAYER
} from './actions'

const initialState = false

let i = -1
let p = -1

const reducer = function playerReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_PLAYER:
      return false
    case PLAYER_READY:
      i++
      p++
      return {
        element: action.payload,
        progress: 0,
        spooling: false,
        playing: true,
        paused: i,
        played: p
      }
    case PLAY_AND_PAUSE:
      if (action.payload) {
        p++
      }
      return Object.assign({}, state, {
        spooling: false,
        playing: action.payload,
        played: p
      })
    case UPDATE_PROGRESS:
      return Object.assign({}, state, {
        progress: action.payload
      })
    case SPOOLING_PLAYER:
      return Object.assign({}, state, {
        spooling: action.payload,
        playing: false
      })
    case PAUSED:
      i++
      return Object.assign({}, state, {
        paused: i
      })
    case SET_PLAYER_ACTION_TYPE:
      return Object.assign({}, state, {
        actionType: action.payload
      })    
    case DESTROY_PLAYER:
      return false
    default:
      return state
  }
}

export default reducer
